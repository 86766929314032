import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useOffers from "../../hooks/useOffers";
import CustomRadio from "../inputs/CustomRadio";

const UnlimitedOffer = ({
  name,
  setForfait,
  selectedForfait,
  usersNumbers,
  error,
}) => {
  const [searchParams] = useSearchParams();
  const isAnnuel = searchParams.get("isAnnuel") === "true";
  const [annuel, setAnnuel] = useState(isAnnuel);
  const [plans, setPlans] = useState();
  const { data: offers } = useOffers("unlimited_offers", (offers) => {
    return {
      forfaitM: offers.forfaitM.filter((offer) => offer.isIllimite === true),
      forfaitA: offers.forfaitA.filter((offer) => offer.isIllimite === true),
    };
  });

  useEffect(() => {
    setForfait(name, null);
  }, [annuel, name, setForfait]);

  useEffect(() => {
    setPlans(annuel ? offers?.forfaitA : offers?.forfaitM);
  }, [offers, annuel]);
  return (
    <div className="w-full">
      {!!plans && (
        <>
          <div className="billing w-full gap-4 flex flex-column md:flex-row  align-items-center justify-content-evenly">
            <CustomRadio
              active={!annuel}
              onClick={() => {
                setAnnuel(!annuel);
                setPlans(!annuel ? offers?.forfaitA : offers?.forfaitM);
              }}
            >
              Mensuel
            </CustomRadio>
            <CustomRadio
              active={annuel}
              onClick={() => {
                setAnnuel(!annuel);
                setPlans(!annuel ? offers?.forfaitA : offers?.forfaitM);
              }}
            >
              Annuel
            </CustomRadio>
          </div>
          <div className="plans w-full gap-4 flex flex-column md:flex-row align-items-center justify-content-between my-3">
            {plans.map((plan) => (
              <CustomRadio
                key={`offer_plan_${plan.id}`}
                error={error}
                active={selectedForfait === plan.id}
                onClick={() => setForfait(name, plan.id)}
              >
                <h3 className="text-center">
                  {plan.nom} |{" "}
                  {Number(
                    parseFloat(plan.prix) * parseInt(usersNumbers)
                  ).toFixed(2)}
                  €HT
                </h3>
                <p className="text-center font-italic	p-2">{plan.description}</p>
              </CustomRadio>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default UnlimitedOffer;
