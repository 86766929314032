const pays = [
  { label: "Allemagne", value: "Allemagne", code: "DE" },
  { label: "Australie", value: "Australie", code: "AU" },
  { label: "Autriche", value: "Autriche", code: "AT" },
  { label: "Belgique", value: "Belgique", code: "BE" },
  { label: "Bulgarie", value: "Bulgarie", code: "BG" },
  { label: "Canada", value: "Canada", code: "CA" },
  { label: "Croatie", value: "Croatie", code: "HR" },
  { label: "République Tchèque", value: "République Tchèque", code: "CZ" },
  { label: "Danemark", value: "Danemark", code: "DK" },
  { label: "Espagne", value: "Espagne", code: "ES" },
  { label: "Etats-Unis", value: "Etats-Unis", code: "US" },
  { label: "Finlande", value: "Finlande", code: "FI" },
  { label: "France", value: "France", code: "FR" },
  { label: "Hong-kong", value: "Hong-kong", code: "HK" },
  { label: "Hongrie", value: "Hongrie", code: "HU" },
  { label: "Irlande", value: "Irlande", code: "IE" },
  { label: "Italie", value: "Italie", code: "IT" },
  { label: "Japon", value: "Japon", code: "JP" },
  { label: "Norvège", value: "Norvège", code: "NO" },
  { label: "Pologne", value: "Pologne", code: "PL" },
  { label: "Portugal", value: "Portugal", code: "PT" },
  { label: "Roumanie", value: "Roumanie", code: "RO" },
  { label: "Royaume-Uni", value: "Royaume-Uni", code: "GB" },
  { label: "Singapour", value: "Singapour", code: "SG" },
  { label: "Slovaquie", value: "Slovaquie", code: "SK" },
  { label: "Suède", value: "Suède", code: "SE" },
  { label: "Suisse", value: "Suisse", code: "CH" },
];

const zones = [
  { label: "ILE DE FRANCE (01)", value: "01" },
  { label: "NORD OUEST (02)", value: "02" },
  { label: "NORD EST (03)", value: "03" },
  { label: "SUD EST (04)", value: "04" },
  { label: "SUD OUEST (05)", value: "05" },
  { label: "NATIONAL (09)", value: "09" },
  { label: "GUADELOUPE (05 90)", value: "0590" },
  { label: "RÉUNION (02 62)", value: "0262" },
  { label: "MARTINIQUE (05 96)", value: "0596" },
  { label: "GUYANE (05 94)", value: "0594" },
];

const typeNumber = [
  { label: "Numéro Standard", value: "standard", isOffert: true },
  { label: "Numéro Argent", value: "argent", isOffert: false },
  { label: "Numéro Or", value: "or", isOffert: false },
  { label: "Personnaliser un numéro", value: "editNumber", isOffert: false },
];

const numbersOptions = {
  "01": ["01 87 20", "01 88 84", "01 89 27"],
  "02": ["02 20 04", "02 59 22", "02 79 93"],
  "03": ["03 39 68", "03 56 57", "03 76 21"],
  "04": ["04 12 10", "04 20 56", "04 23 16", "04 51 20", "04 85 62"],
  "05": ["05 25 32", "05 32 96", "05 48 28"],
  "09": ["09 72 01", "09 72 79"],
  "0590": ["05 90 19"],
  "0262": ["02 62 07"],
  "0596": ["05 96 32"],
  "0594": ["05 94 97"],
};

const editNumberTypeMessages = {
  standard: "",
  argent:
    "Le numéro que vous avez demandé est un numéro Argent. En le choisissant, vous serez facturé de 49€HT",
  or: "Le numéro que vous avez demandé est un numéro OR. En le choisissant, vous serez facturé de 99€HT",
};

const freeTrial = [
  "Essai gratuit et sans engagement annulable à tout moment",
  "Standard téléphonique 100% configurable en ligne",
  "Renvoi d’appels vers fixes et mobiles France Métropolitaine + DOM",
];

const numberUsersOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
];

export {
  pays,
  zones,
  typeNumber,
  numbersOptions,
  editNumberTypeMessages,
  freeTrial,
  numberUsersOptions,
};
