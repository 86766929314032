import sendRequest from "../API";
import API_Routes from "../API/API_Routes";

const createCommande = async (commande, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.createCommande,
      "POST",
      commande,
      signal
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export { createCommande };
