import { RouterProvider } from "react-router-dom";
import "./assets/css/App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import router from "./router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ToastContainer />
    </QueryClientProvider>
  );
}
