import { CheckIcon } from "../../assets/svgs";
import { freeTrial } from "../../static";
const OfferDetailsTry = ({ commande }) => {
  return (
    <div className="w-full my-2">
      <h1>Récapitulatif de la commande </h1>
      <ul className="flex flex-column gap-3 my-4 text-xs md:text-base">
        <li className="flex justify-content-between align-items-center">
          <span className="text-md md:text-lg lg:text-xl">
            Forfait illimité
            {commande.forfait?.isAnnuel ? " annuel" : " mensuel"} :{" "}
          </span>
          <span className="text-md md:text-lg lg:text-xl">
            {Number(
              parseFloat(commande.forfait.prix) *
                parseInt(commande.users_number)
            ).toFixed(2)}
            € HT/
            {commande.forfait?.isAnnuel ? "an" : "mois"}
          </span>
        </li>
        <li className="flex justify-content-between align-items-center">
          <span className="text-md md:text-lg lg:text-xl">
            Numéro{" "}
            {["standard"].includes(commande.type_cle_api.type_key)
              ? "STANDARD"
              : ["argent_doublette", "argent_triplette"].includes(
                  commande.type_cle_api.type_key
                )
              ? "ARGENT"
              : "OR"}
          </span>
          <span className="text-md md:text-lg lg:text-xl">
            {commande.type_cle_api.prix_type}€ HT
          </span>
        </li>
        <li className="flex justify-content-between align-items-center">
          <span className="text-md md:text-lg lg:text-xl">
            Nombre utilisateur :
          </span>
          <span className="text-md md:text-lg lg:text-xl">
            {commande.users_number}
          </span>
        </li>
        {freeTrial.map((item, index) => (
          <li key={index} className="flex justify-content-between">
            <span>{item}</span>
            <span>
              <CheckIcon fill="var(--primary-color)" />
            </span>
          </li>
        ))}
      </ul>
      <div className="flex justify-content-between text-xl md:text-2xl">
        <span>Total à payer aujoudhui :</span> <span>0€</span>
      </div>
    </div>
  );
};

export default OfferDetailsTry;
