import { z } from "zod";
import errorMessages from "./errorMessages";
export const informationCompanySchema = z.object({
  forfait: z.number({
    required_error: errorMessages.unSelectedRadio,
  }),
  users_number: z
    .number({
      required_error: errorMessages.required,
    })
    .min(1)
    .max(20),
  nom: z.string({
    required_error: errorMessages.required,
  }),
  prenom: z.string({
    required_error: errorMessages.required,
  }),
  telephone: z.string({
    required_error: errorMessages.required,
  }),
  email: z
    .string({
      required_error: errorMessages.required,
    })
    .email({ message: errorMessages.email }),
  nom_structure: z.string({
    required_error: errorMessages.required,
  }),
  code_postal: z
    .string({
      required_error: errorMessages.required,
    })
    .min(5, { message: errorMessages.codePostal })
    .max(5, { message: errorMessages.codePostal }),
  numeroRue: z.string({
    required_error: errorMessages.required,
  }),
  rue: z.string({
    required_error: errorMessages.required,
  }),
  ville: z.string({
    required_error: errorMessages.required,
  }),
  pays: z.string({
    required_error: errorMessages.required,
  }),
});
