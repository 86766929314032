import React from "react";

const CustomRadio = ({ children, active, onClick, error = false }) => {
  return (
    <span
      onClick={onClick}
      className={`relative py-2 flex flex-column align-items-center justify-content-center w-full md:w-6 border-round-lg cursor-pointer border-1 ${
        error ? "border-red-500" : "border-300"
      } ${active ? "primary-bg light-primary-text" : ""}`}
    >
      <span
        style={{
          width: "10px",
          height: "10px",
        }}
        className={`absolute border-circle left-0 top-0 m-2 border-300 ${
          active ? "border-3" : "border-1"
        }`}
      ></span>
      {children}
    </span>
  );
};

export default CustomRadio;
