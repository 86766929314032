import { useState, useEffect } from "react";

import "../assets/css/Success.css";

import logo from "../assets/logo/logo.svg";
import checked from "../assets/images/success/picto_checked.svg";

const Success = () => {
  let jsonFile = require(`../languages/fr-FR.json`);

  const [lang, setLang] = useState({
    title: jsonFile["success-title"],

    merci: jsonFile["success-merci"],

    infos: jsonFile["success-infos"],
    email: jsonFile["success-email"],

    config: jsonFile["success-config"],
  });

  useEffect(() => {
    let langCode = navigator.language;

    switch (langCode) {
      case "en-EN":
        let jsonFile = require(`../languages/en-EN.json`);

        setLang({
          ...lang,
          title: jsonFile["success-title"],

          merci: jsonFile["success-merci"],

          infos: jsonFile["success-infos"],
          email: jsonFile["success-email"],

          config: jsonFile["success-config"],
        });
        break;

      default:
        break;
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="success-commande">
      <div className="div_img">
        <img width="400rem" height="auto" src={logo} alt="logo-colortelecom" />
      </div>
      <div className="div_paiement_reussi flexed">
        <img src={checked} alt="checked-colortelecom" />
        <div className="paiement_reussi">
          <p>Paiement réussi</p>
        </div>
      </div>
      <div className="div_mrc_confiance">
        <p className="mrc_confiance">Merci de nous faire confiance.</p>
      </div>
      <div className="div_envoi_email">
        <p className="envoi_email">
          Vous allez recevoir dans quelques minutes vos identifiants de
          connexion <br />à votre espace client{" "}
          <a href="https://monstandardenligne.com">
            https://monstandardenligne.com
          </a>
        </p>
      </div>
      <div className="div_adresse_email">
        <div className="adresse_email">
          <p className="envoi_email" style={{ paddingTop: "20px" }}>
            Si vous avez des questions, vous pouvez nous contacter à
          </p>
          <a
            className="mail flex justify-content-center"
            href="mailto:support@standardenligne.fr"
          >
            {lang.email}
          </a>
        </div>
      </div>
      {/* <div className="div_btn_config">
                <a className="btn btn_config flexed" href="config/step1" rel="noreferrer">
                    <img src={setting} alt="checked-colortelecom" />
                    <span className="btn_text">{lang.config}</span>
                </a>
            </div> */}
    </div>
  );
};

export default Success;
