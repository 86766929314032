import React, { useState, useEffect } from "react";
import SidePanel from "./SidePanel";
import { Rating } from "primereact/rating";
import PhoneIcon from "../assets/images/icons8-phone-50.png";
import side_img from "../assets/images/side_img_1.png";
import side_img2 from "../assets/images/side_img_2.png";
import side_img3 from "../assets/images/side_img_3.png";
import "../assets/css/slidePanel/slidePanel.css";

const sidePanels = [
  {
    title: (
      <>
        à partir de <br />
        <strong className="text-5xl">19.90€HT</strong>
      </>
    ),
    image: side_img,
    content: [
      "Appels illimités vers Fixes et Mobiles France Métropolitaine",
      "Standard Téléphonique Virtuel avec toutes les fonctionnalités",
      "Application mobile et Softphone pour chaque utilisateur",
    ],
  },
  {
    title: "Intégrez votre CRM en 1 clic*",
    image: side_img2,
    subtitle: "inclus dans le Forfait Pro",
    content: [],
  },
  {
    title: "Appels illimités +70 destinations internationales",
    image: side_img3,
    subtitle: "inclus dans le Forfait Pro",
    content: [],
  },
];

export default function SlidePanel() {
  const [currentSlide, setCurrentSlide] = useState(1);

  const handleIndicatorClick = (slide) => {
    setCurrentSlide(slide);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide % 3) + 1);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <aside className="aside-container">
      {sidePanels.map((panel, index) => (
        <SidePanel
          visibility={currentSlide === ++index}
          key={index}
          title={panel.title}
          image={panel.image}
          subtitle={panel?.subtitle}
          content={panel.content}
        />
      ))}

      <div className="aside-avis">
        <strong>Des milliers d’entreprises satisfaites: </strong>
        <Rating
          unstyled
          value={5}
          readOnly
          cancel={false}
          style={{
            gap: 3,
            color: "#16c599",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <strong
          style={{
            textDecoration: "underline",
          }}
        >
          4.9/5 (493 avis)
        </strong>
      </div>
      <div className="aside-nous-appel">
        <span>Besoin d’aide ? Appelez-nous au </span>
        <strong
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <img src={PhoneIcon} width={"30px"} height={"30px"} alt="phone" />
          01 87 200 200
        </strong>
      </div>

      <div className="aside-panel-control">
        <div
          className={`indicator ${currentSlide === 1 ? "active" : ""}`}
          onClick={() => handleIndicatorClick(1)}
        />
        <div
          className={`indicator ${currentSlide === 2 ? "active" : ""}`}
          onClick={() => handleIndicatorClick(2)}
        />
        <div
          className={`indicator ${currentSlide === 3 ? "active" : ""}`}
          onClick={() => handleIndicatorClick(3)}
        />
      </div>
    </aside>
  );
}
