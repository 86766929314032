import React from "react";
import { Knob } from "primereact/knob";

const Steps = ({ step, maxStep }) => {
  return (
    <div className="flex align-items-center gap-3 w-12 text-sm">
      <Knob
        showValue={false}
        value={(step / maxStep) * 100}
        valueColor="#264A6E"
        readOnly
        size={30}
      />
      <span>
        Étape {step} sur {maxStep}
      </span>
    </div>
  );
};

export default Steps;
