function formatPhoneNumberWithSpaces(phoneNumber) {
  // Ajouter un espace tous les 2 chiffres
  return phoneNumber.replace(/(\d{2})/g, "$1 ").trim();
}

const getNumbersByType = (numbers, type) => {
  if (type === "standard") {
    const formattedStandard = numbers[`${type}`].map((item) => {
      return { ...item, number: formatDoublette(item.number) };
    });
    return formattedStandard;
  }
  const doublette = numbers[`${type}`]?.doublette;
  const triplette = numbers[`${type}`]?.triplette;
  const formattedDoublette = doublette.map((item) => {
    return { ...item, number: formatDoublette(item.number) };
  });
  const formattedTriplette = triplette.map((item) => {
    return { ...item, number: formatTriplette(item.number) };
  });
  return [...formattedDoublette, ...formattedTriplette];
};

const shuffleNumbers = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

const findNumberType = (numbersArray, number, isLoading = false) => {
  if (!numbersArray || isLoading) return false;

  const standardNumber = numbersArray.standard;
  const isStandard = standardNumber.filter((item) => item.number === number);
  if (isStandard.length)
    return { number: isStandard[0].number, type: "standard" };

  const isOrDoublette = numbersArray.or.doublette.filter(
    (item) => item.number === number
  );
  if (isOrDoublette.length)
    return { number: isOrDoublette[0].number, type: "or_doublette" };
  const isOrTriplette = numbersArray.or.triplette.filter(
    (item) => item.number === number
  );
  if (isOrTriplette.length)
    return { number: isOrTriplette[0].number, type: "or_triplette" };

  const isArgentDoublette = numbersArray.argent.doublette.filter(
    (item) => item.number === number
  );
  if (isArgentDoublette.length)
    return { number: isArgentDoublette[0].number, type: "argent_doublette" };
  const isArgentTriplette = numbersArray.argent.triplette.filter(
    (item) => item.number === number
  );
  if (isArgentTriplette.length)
    return { number: isArgentTriplette[0].number, type: "argent_triplette" };
};

const formatDoublette = (number) => {
  return number?.replace(/(\d{2})(?=\d{2})/g, "$1 ");
};

const formatTriplette = (number) => {
  const firstPart = number.substring(0, 2);
  const secondPart = number.substring(2, 4);
  const thirdPart = number.substring(4, 7);
  const fourthPart = number.substring(7);

  return `${firstPart.padStart(2, "0")} ${secondPart.padStart(
    2,
    "0"
  )} ${thirdPart.padStart(3, "0")} ${fourthPart}`;
};

export {
  formatPhoneNumberWithSpaces,
  getNumbersByType,
  shuffleNumbers,
  findNumberType,
  formatDoublette,
  formatTriplette,
};
