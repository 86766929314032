import sendRequest from "../API";
import API_Routes from "../API/API_Routes";

const createClient = async (client, signal = null) => {
  try {
    const response = await sendRequest(
      API_Routes.createClient,
      "POST",
      client,
      signal
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export { createClient };
