import { useStripe, useElements } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import MainButton from "../inscription/MainButton";
import { toast } from "react-toastify";
import Secure3D from "../../assets/images/3d-secure.png";

import {
  stripeAttachPaymentMethod,
  intentHandler,
  createStripeSubscription,
  stripeConfirmSetupIntent,
} from "../../services/stripe";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import routes from "../../router/routes";

export default function CheckoutForm({ commande, clientSecret }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const methods = useForm();

  /*   const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: card,
    });

    // Handle error cases
    if (result.error) {
      toast.error(result.error, {
        position: "bottom-right",
      });
    } else {
      try {
        await stripeAttachPaymentMethod({
          commande,
          id_method: result.paymentMethod.id,
        });

        const createPaymentIntentResponse = await stripeCreatePaymentIntent({
          commande,
        });

        // Handle if payment intent required
        // if payment required response {success:false, intent: Stripe Payment Intent Object } it mean that the number has price and must be purchased
        if (createPaymentIntentResponse?.success === false) {
          const intent = createPaymentIntentResponse.intent;
          const intentResponse = await intentHandler(
            intent,
            stripe,
            stripeConfirmPaymentIntent
          );
          if (intentResponse?.success === false) throw intentResponse.message;
        }

        const createSubscriptionResponse = await createStripeSubscription({
          commande,
        });

        if (createSubscriptionResponse?.success === false) {
          const intent = createSubscriptionResponse.intent;
          const intentResponse = await intentHandler(
            intent,
            stripe,
            stripeConfirmSetupIntent
          );
          if (intentResponse?.success === false) throw intentResponse.message;
        }
        navigate(`${routes.successCheckout}?commande=${commande}`);
      } catch (error) {
        if (error?.success === false)
          toast.error(error.message, {
            position: "bottom-right",
          });
      }
    }
  }; */

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return null;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (error) {
      toast.error(error.message, {
        position: "bottom-right",
      });
      return null;
    }

    const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);
    switch (setupIntent.status) {
      case "succeeded":
        try {
          await stripeAttachPaymentMethod({
            commande,
          });
          const createSubscriptionResponse = await createStripeSubscription({
            commande,
          });
          if (createSubscriptionResponse?.success === false) {
            const intent = createSubscriptionResponse.intent;
            const intentResponse = await intentHandler(
              intent,
              stripe,
              stripeConfirmSetupIntent
            );
            if (intentResponse?.success === false) throw intentResponse.message;
          }
          navigate(`${routes.successCheckout}?commande=${commande}`);
          break;
        } catch (error) {
          toast.error(error?.message, {
            position: "bottom-right",
          });
          break;
        }

      case "processing":
        /* setMessage(
          "Processing payment details. We'll update you when processing is complete."
        ); */
        break;

      case "requires_payment_method":
        // Redirect your user back to your payment page to attempt collecting
        // payment again
        toast.error(setupIntent.last_setup_error.message, {
          position: "bottom-right",
        });
        break;

      default:
        break;
    }
  };

  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)} className="mt-4">
      <div className="border-1 border-300	p-3 my-2">
        <CardSection />
      </div>
      <div className="w-full  flex align-items-center my-3">
        <span className="font-medium text-md font-italic	">
          Protocole sécurisé par le système 3D Secure{" "}
        </span>
        <span className="mx-4 flex align-items-center">
          <img src={Secure3D} width={25} height={28} alt="3D secure payment" />
        </span>
      </div>
      <div className="flex relative w-full align-items-center justify-content-center lg:justify-content-end mt-6 mb-3">
        <MainButton
          label="Configurer mon standard"
          disabled={methods.formState.isSubmitting || !stripe}
        />
      </div>
    </form>
  );
}
